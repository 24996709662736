import React from 'react'

import { format, formatISO } from 'date-fns'
import { graphql } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import { MDXRenderer } from 'gatsby-plugin-mdx'

import {
  AudioPlayer,
  Container,
  GuestCard,
  ImageCover,
  JoinDiscord,
  LinkCustom,
  PrevNext,
  Seo,
  Share,
} from '../../components'

const Episode: React.FC = ({ data, pageContext }) => {
  const { mdx, site, currentGuest } = data
  const { frontmatter, fields, id } = mdx

  const {
    title,
    subtitle,
    publicationDate,
    country,
    episodeNumber,
    imageCover,
    imageCoverAlt,
    url,
    duration,
    author,
  } = frontmatter

  const dateFormated = format(new Date(publicationDate), 'PP')
  const dateFormatedTime = formatISO(new Date(publicationDate))
  const currentUrl = `${site.siteMetadata.siteUrl}${fields.slug}`

  console.log('currentGuest', currentGuest)

  return (
    <article>
      <Seo
        title={`EP${episodeNumber}: ${title}`}
        description={subtitle}
        image={imageCover}
        imageAlt={imageCoverAlt}
        pathname={pageContext.slug}
        audioSrc={url}
        datePublished={dateFormatedTime}
        isAudioPost
      />
      <header className="bg-white">
        <Container>
          <div className="pt-52 pt-32">
            <div className="grid grid-cols-1 md:grid-cols-3 gap-10">
              <div className="relative">
                <ImageCover
                  image={imageCover}
                  alt={imageCoverAlt}
                  duration={duration}
                  episodeNumber={episodeNumber}
                />
              </div>

              <div className="pb-16 mb:px-10 col-span-2">
                <div className="mb-3 sm:mb-7">
                  <span
                    itemProp="name"
                    className="uppercase font-bold text-primary sm:text-2xl tracking-widest"
                  >
                    {country}
                  </span>

                  <span className="px-2 text-3xl" aria-hidden="true">
                    •
                  </span>

                  <span className="published">
                    <time dateTime={dateFormatedTime} className="uppercase sm:text-2xl font-light">
                      {dateFormated}
                    </time>
                  </span>
                </div>

                <h1 className="text-4xl font-bold text-gray-900 sm:text-5xl mb-4">{title}</h1>

                <div className="mb-8 flex items-center text-gray-500">
                  <StaticImage
                    src="../../../static/assets/david-dias/david-dias.png"
                    alt="David Dias"
                    width={40}
                    loading="eager"
                    className="rounded-full mr-2 shadow"
                  />
                  <div>
                    Hosted by{' '}
                    <LinkCustom to="/about-david-dias" className="underline">
                      {author}
                    </LinkCustom>
                  </div>
                </div>

                <p className="text-xl leading-8 mb-7 font-serif sm:text-xl text-gray-600">
                  {subtitle}
                </p>

                <Share url={currentUrl} />
              </div>
            </div>
          </div>
        </Container>
      </header>

      <Container className="-mt-8">
        <AudioPlayer url={url} id={id} />

        <div className="max-w-xl mx-auto my-20 markdown">
          <MDXRenderer>{mdx.body}</MDXRenderer>
        </div>

        <JoinDiscord />

        <GuestCard guest={currentGuest} />

        <PrevNext pageContext={pageContext} />
      </Container>
    </article>
  )
}

export default Episode

export const query = graphql`
  query EpisodeQuery($slug: String!, $guest: String) {
    site {
      siteMetadata {
        siteUrl
      }
    }
    mdx(fields: { slug: { eq: $slug } }) {
      fileAbsolutePath
      frontmatter {
        title
        subtitle
        publicationDate
        url
        episodeNumber
        country
        imageCover
        imageCoverAlt
        duration
        guest
        author
      }
      id
      body
      fields {
        slug
      }
    }
    currentGuest: allAirtableGuests(filter: { data: { Full_name: { eq: $guest } } }) {
      nodes {
        data {
          Full_name
          Avatar___profile_image {
            thumbnails {
              large {
                url
              }
            }
          }
          Title
          Country {
            data {
              Name_country
            }
          }
          Personal_website___blog
          Code_country
          Twitter
          LinkedIn
          Instagram
          Github
          Facebook_profile
          Bio
        }
      }
    }
  }
`
